
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from '@/pinia/store';
import { useRoute } from 'vue-router';
import NavigationHeaderVue from './components/NavigationHeader CMG/NavigationHeader.vue';
// import ShoppingCart from './components/ShoppingCart/ShoppingCart.vue';
// import Nav_StatusHeader from '@/components/Nav_StatusHeader/Nav_StatusHeader.vue'
export default {
  components: {
    NavigationHeaderVue,
    // Nav_StatusHeader
    // ShoppingCart,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isPlaceCheckout = ref<boolean>(false);
    const hasLogin = computed<boolean>(() => store.$state.hasLogin);
    const urlProps = ref<string>('')

    watch(() => route.fullPath, (value: string) => {
      urlProps.value = value ? value : ''
      // isPlaceCheckout.value = value.search('checkout') > -1 ? false : true;
    });

    onMounted(() => {
      document.title = localStorage.getItem('SiteName') || ''
    });

    

    return {
      hasLogin,
      isPlaceCheckout,
      route,
      urlProps
    };
  }
}
