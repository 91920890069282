
import Nav_StatusHeader from '@/components/Nav_StatusHeader/Nav_StatusHeader.vue'
  import { ref, onMounted} from 'vue';
  import router from '@/router/index';
    export default {
      components: {
        Nav_StatusHeader
      },
      props: {
        componentActive: {
            type: String,
            required: true,
            default: '',
        }
    },
      setup() {
        const route = router;
        const SiteName = ref<string>('')
        const urlImg = ref<string>('')
        const returnHome = () => {
          if(localStorage.getItem('Lang') == '1000001') {
            route.push({path: `/booking-golf/en`})
          } else {
            route.push({path: `/booking-golf/vi`})
          }
        }
          onMounted( () => {
            SiteName.value = localStorage.getItem('SiteName') || ''
            urlImg.value = `/${localStorage.getItem('ImageFolder')}/logo.png`
          })
          return {
            SiteName,
            urlImg,
            returnHome
          }
      }
    }
