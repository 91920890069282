
import { ref, onMounted, computed, watch } from 'vue';
type Prop = {
    componentActive: string;
}
export default {
    props: {
        componentActive: {
            type: String,
            required: true,
            default: '',
        }
    },
    setup(props: any) {
        // onMounted(() => {
        //     console.log(props)
        // })
        const AllowBoPaymentOnline = localStorage.getItem('AllowBoPaymentOnline')
        watch(props, () => {
            if(props.componentActive.includes('/booking-golf')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('hide-status')
                dt[0].classList.add('active')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.remove('active')
                dt1[0].classList.add('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.remove('active')
                dt2[0].classList.add('hide-status')
            } else if(props.componentActive.includes('/payment-booking')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('active')
                dt[0].classList.add('hide-status')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.add('active')
                dt1[0].classList.remove('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.remove('active')
                dt2[0].classList.add('hide-status')
            } else if (props.componentActive.includes('/result')) {
                const dt = document.getElementsByClassName('status-select-teetime') 
                dt[0].classList.remove('active')
                dt[0].classList.add('hide-status')
                const dt1 = document.getElementsByClassName('status-payment') 
                dt1[0].classList.remove('active')
                dt1[0].classList.add('hide-status')
                const dt2 = document.getElementsByClassName('status-success') 
                dt2[0].classList.add('active')
                dt2[0].classList.remove('hide-status')
            }
        })
        return {
            AllowBoPaymentOnline
        }
    }
}
