import axios from 'axios';
import {
    URL_CMG,
    RAW_JSON,
    CMG_GET_LIST_COURSE,
    CMG_GET_RATE_DEFAULT,
    CMG_GET_LIST_TEETIME,
    CMG_GET_HOLE_DEFAULT,
    CMG_GET_PRICE_TEETIME,
    CMG_CREATE_BOOKING,
    CMG_CREATE_BOOKING_CMG,
    CMG_GET_LIST_PAYMENT_GETWAYS,
    CMG_GET_BOOKING_RBK02A,
    CMG_GET_BOOKING_RBK02D,
    CMG_GET_GATE_WAY_IPM01,
    CMG_GET_STATUS_PAYMENT,
    CMG_UPDATE_BOOKING_INFO,
    CMG_GET_LANG,
    CMG_GET_CONFIG,
    CMG_GET_CHECK_STATUS_PAYMENT
} from './constans';
import { successReponse } from './handle-reponse';
import {
    postBody
} from './request-options';
const fetchCMG_getLang = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_LANG, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_getConfig = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_CONFIG, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_getStatusPayment = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_STATUS_PAYMENT, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_getCheckStatusPayment = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_CHECK_STATUS_PAYMENT, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_updateBookingInfo = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_UPDATE_BOOKING_INFO, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_listPaymentGetways = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_LIST_PAYMENT_GETWAYS, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_Course = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_LIST_COURSE, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_HoleDefault = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_HOLE_DEFAULT, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_RateDefault = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_RATE_DEFAULT, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}

const fetchCMG_ListTeeTime = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_LIST_TEETIME, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_PriceTeetime = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_PRICE_TEETIME, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_CreateBooking = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_CREATE_BOOKING, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_CreateBookingCMG = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_CREATE_BOOKING_CMG, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_GetBookingRbk02a = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_BOOKING_RBK02A, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_GetBookingRbk02d = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_BOOKING_RBK02D, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
const fetchCMG_createInvoiceIMP01 = async (data: any) => {
    const res = await axios.post(URL_CMG + CMG_GET_GATE_WAY_IPM01, postBody(data), RAW_JSON).then((res) => successReponse(res))
    return res;
}
export const cmg_api = {
    fetchCMG_Course,
    fetchCMG_RateDefault,
    fetchCMG_ListTeeTime,
    fetchCMG_HoleDefault,
    fetchCMG_PriceTeetime,
    fetchCMG_CreateBooking,
    fetchCMG_CreateBookingCMG,
    fetchCMG_listPaymentGetways,
    fetchCMG_GetBookingRbk02a,
    fetchCMG_GetBookingRbk02d,
    fetchCMG_createInvoiceIMP01,
    fetchCMG_getStatusPayment,
    fetchCMG_updateBookingInfo,
    fetchCMG_getLang,
    fetchCMG_getConfig,
    fetchCMG_getCheckStatusPayment
}




