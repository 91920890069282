import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue3 } from 'bootstrap-vue-3';
// import { createPinia, setActivePinia } from "pinia";
import pinia from '@/pinia/create-pinia';
import '@/styles/style.scss';
import '@/styles/ezIcon/ezICon.scss';
import ezIcon from '@/components/ezIcon/ezIcon.vue'
import Datepicker from '@vuepic/vue-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css'
import { createI18n } from 'vue-i18n-lite';
import router from './router'
import {cmg_api} from '@/api/cmg_api';
// const {gzip} = require('node-gzip');
// gzip('aaaa').then((res:any) => {
//     console.log(res)
// })
import 'vue3-toastify/dist/index.css';
import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify';
import {EncryptData} from '@/mixin'


if(!localStorage.getItem('Lang')) {
    localStorage.setItem('Lang', '1000000');
}
if(location.pathname.split('/')[1] == '') {
    localStorage.setItem('Lang', '1000000');
} else if(location.pathname.split('/')[1] == 'booking-golf') {
    if(location.pathname.split('/')[2] == '' || !location.pathname.split('/')[2]) {
        localStorage.setItem('Lang', '1000000');
    } else if (location.pathname.split('/')[2].toUpperCase() == 'VI' && localStorage.getItem('Lang') != '1000000') {
        localStorage.setItem('Lang', '1000000');
    } else if(location.pathname.split('/')[2].toUpperCase() == 'EN' && localStorage.getItem('Lang') != '1000001') {
        localStorage.setItem('Lang', '1000001');
    } else {
        localStorage.setItem('Lang', '1000000');
    }
}


const locale = localStorage.getItem('Lang') || '1000000'

await cmg_api.fetchCMG_getConfig({
    Url: location.origin,
    KeyGroup: 'BO_CONFIG',
    CheckSum: EncryptData([location.origin])
}).then(res => {
    if(res.data.Status == '200') {
        localStorage.setItem('AllowBoPaymentOnline', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "ALLOW_BO_PAYMENT_ONLINE").KeyValue)
        localStorage.setItem('ChainId', res.data.Data.ChainId)
        localStorage.setItem('SiteId', res.data.Data.SiteId)
        localStorage.setItem('TimeZone', res.data.Data.TimeZone)
        localStorage.setItem('ImageFolder', res.data.Data.ImageFolder)
        localStorage.setItem('TimeZoneOffset', res.data.Data.TimeZoneOffset)
        localStorage.setItem('BannerCourse', JSON.stringify(res.data.Data.ImagePathCourse))
        localStorage.setItem('SiteName', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_site_name").KeyValue)
        localStorage.setItem('GolfClassDefault', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_golfclass_default").KeyValue)
        localStorage.setItem('TaDefault', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "bo_config_ta_default").KeyValue)
        localStorage.setItem('AccessToken', res.data.Data.AccessToken)
        localStorage.setItem('BoPaymentOnlineTerm', res.data.Data.ConfigValue.find((x:any) => x.KeyCode == "BO_PAYMENT_ONLINE_TERM").KeyValue)
    }
})
const app = createApp(App);
await cmg_api.fetchCMG_getLang({
    Url: location.origin,
    KeyGroup: 'FRONT_END_BO',
    CheckSum: EncryptData([location.origin])
}).then(res => {
    if(res.data.Status == '200') {
        const i18n = createI18n({
            locale,
            fallbackLocale: locale,
            messages: JSON.parse(res.data.Data)
        })
        app.use(i18n)
    }
})
// document.querySelectorAll('.loading')[0].classList.remove('loading')
// document.querySelectorAll('.loading')[0].remove()
document.getElementById('loading-bg')?.remove()
app.use(Vue3Toasity,
    {
      autoClose: 3000,
      // ...
    } as ToastContainerOptions,)
app.use(pinia);
app.use(router);
app.use(BootstrapVue3);
app.component('DatepickerPlugin', Datepicker);
app.component('ez-icon', ezIcon);
app.component('vSelect', vSelect);
app.mount("#app");
export default app;
