import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54275466"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"margin-top":"auto","margin-bottom":"auto"} }
const _hoisted_3 = { style: {"width":"65%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Nav_StatusHeader = _resolveComponent("Nav_StatusHeader")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_nav = _resolveComponent("b-nav")!

  return (_openBlock(), _createBlock(_component_b_nav, {
    class: "nav-header w-100 d-flex py-3 ezGolf-nav",
    vertical: true,
    pills: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "w-100 d-flex justify-content-between" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.returnHome())),
            style: {"cursor":"pointer","display":"flex","width":"35%"}
          }, [
            _createElementVNode("img", {
              src: $setup.urlImg,
              style: {"width":"75px"},
              alt: ""
            }, null, 8, _hoisted_1),
            _createElementVNode("h2", _hoisted_2, _toDisplayString($setup.SiteName), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Nav_StatusHeader, { componentActive: $props.componentActive }, null, 8, ["componentActive"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}